import React from 'react';
import Loadable from 'react-loadable';
import { components } from 'react-select';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import selectPreviewData from '../../../../data/previews/select.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const AvatarUser = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarUser;
    return <Component {...props} />;
  },
});

const Select = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Select;
    return <Component {...props} />;
  },
});

const CreatableSelect = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CreatableSelect;
    return <Component {...props} />;
  },
});

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

const options = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
  { label: 'Marv Albert', value: 'marv', isDisabled: true },
  { label: 'Al Attles', value: 'al' },
  { label: 'Red Auerbach', value: 'red' },
  { label: 'Elgin Baylor', value: 'elgin' },
  { label: 'Dave Bing', value: 'dave' },
  { label: 'Larry Bird', value: 'larry' },
];

const customOptions = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem', initials: 'KAJ' },
  { label: 'Marv Albert', value: 'marv', initials: 'MA' },
  { label: 'Al Attles', value: 'al', initials: 'AA' },
  { label: 'Red Auerbach', value: 'red', initials: 'RA' },
  { label: 'Elgin Baylor', value: 'elgin', initials: 'EB' },
  { label: 'Dave Bing', value: 'dave', initials: 'DB' },
  { label: 'Larry Bird', value: 'larry', initials: 'LB' },
];

const Option = props => {
  return (
    <components.Option {...props} style={{ flexDirection: 'row' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AvatarUser
          initials={props.data.initials}
          className="uni-margin--half--right"
        />
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  );
};

const groupOptions = [
  {
    label: 'Offense',
    options: [
      { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
      { label: 'Marv Albert', value: 'marv', disabled: true },
      { label: 'Al Attles', value: 'al' },
      { label: 'Red Auerbach', value: 'red' },
    ],
  },
  {
    label: 'Defense',
    options: [
      { label: 'Elgin Baylor', value: 'elgin' },
      { label: 'Dave Bing', value: 'dave' },
      { label: 'Larry Bird', value: 'larry' },
    ],
  },
];

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Select"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Select" />
      <V5Notice
        componentName="Select and Creatable Select"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-selects--docs"
      />

      <PageNavigation
        links={[
          'Type',
          'Size',
          'Pills',
          'Microcopy',
          'Mobile',
          'Usage',
          'Accessibility',
          'Platform',
        ]}
      />

      <Section title="Type">
        <Paragraph>
          We have three different types of selection when selecting items.
        </Paragraph>
        <ComponentPreview
          name="SelectType"
          layout="halfWidth"
          previewData={selectPreviewData.types}>
          <Select
            formSize="medium"
            placeholder="Find an athlete"
            options={options}
          />
        </ComponentPreview>
        <List>
          <li>
            <strong>Single:</strong> Functioning similarly to a radio, the
            single select is more space efficient—especially as the number of
            options (and each option's length) increases. If the list grows too
            large, consider a lookup select.
          </li>
          <li>
            <strong>Lookup:</strong> Working best with long, preloaded lists,
            the lookup select, gives the user a good idea of how to filter its
            contents.
          </li>
          <li>
            <strong>Lookup Multi:</strong> If a list is 15+ items long, a lookup
            multi-select works best.
          </li>
        </List>
      </Section>
      <Section title="Size">
        <Paragraph>
          Consider the interface's density and the select's position when
          choosing between the three available sizes. The density (standard,
          compact) is dictated by the form modifier component. Check{' '}
          <Link href="/components/forms/form-modifier/design">
            those guidelines
          </Link>{' '}
          for more detailed recommendations.
        </Paragraph>
        <ComponentPreview
          name="SelectSize"
          layout="halfWidth"
          previewData={selectPreviewData.sizes}>
          <Select placeholder="Select an athlete" options={options} />
        </ComponentPreview>
      </Section>

      <Section title="Pills">
        <Paragraph>
          Pills visually display selected items when a multi-select or lookup
          multi-select is used. They can filter content, list a large number of
          selections, and may be edited or removed.
        </Paragraph>
        <List type="unstyled">
          <li>
            <strong>Backspace:</strong> In creatable selects, you can hit
            backspace to remove the last item listed.
          </li>
          <li>
            <strong>Dismiss a pill:</strong> Each pill can be removed by
            clicking the dismiss specific to that item or by tabbing to that
            pill's dismiss icon and using the{' '}
            <KeyboardShortcut>enter</KeyboardShortcut> key in creatable selects.
          </li>
          <li>
            <strong>Dismiss all pills:</strong> The entire multi-select has its
            own dismiss positioned on the right. Clicking that will remove all
            selected items at once.
          </li>
          <li>
            <strong>Edit a pill:</strong> Enable editing for a single pill by
            double-clicking into or hitting{' '}
            <KeyboardShortcut>enter</KeyboardShortcut> within the content area
            in creatable selects.
          </li>
        </List>
        <ComponentPreview
          name="SelectDismiss"
          layout="halfWidth"
          previewData={selectPreviewData.dismisses}>
          <CreatableSelect
            isMulti
            formSize="medium"
            placeholder="Find an athlete"
            options={options}
            defaultValue={[
              { label: 'Al Attles', value: 'al' },
              { label: 'Red Auerbach', value: 'red' },
              { label: 'Elgin Baylor', value: 'elgin' },
            ]}
          />
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Sometimes forms can be lengthy. Users benefit from being provided
          additional detail about what information is required. In some cases,
          the information input doesn't meet the criteria of the form element.
          It's our job to guide them through successful completion of their
          task.
        </Paragraph>
        <SectionSubhead>Placeholder Text</SectionSubhead>
        <List>
          <li>
            <strong>Single select</strong> placeholders should read “Select a/an
            __”. By limiting the CTA to <em>select</em>, we're suggesting there
            is a list to choose from. The article a/an makes it clear they can
            only choose one.
          </li>
          <li>
            <strong>Lookup and lookup multi-select</strong> placeholders should
            read “Select items,” making it clear that the user can and should
            choose more than one.
          </li>
        </List>

        <SectionSubhead>Help and Error Text</SectionSubhead>
        <List type="unstyled">
          <li>
            <strong>Help text:</strong> Give additional context to convey how
            the information will be used.
          </li>
          <li>
            <strong>Error text:</strong> When validation fails, error text
            should replace the form's help text. The help text must be updated
            explaining why an error occurred and how to fix it.
          </li>
          <li>
            <strong>Single item error:</strong> In some cases, validation may
            fail on a single item due to formatting or another reason. In these
            cases, highlight the incorrect item and replace the form's help text
            with steps to repair it.
          </li>
        </List>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Single select options are typically single words or names. If your
          options go beyond that, be sure to follow the{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product#Non-Label%20UI%20Text">
            non-label text guidelines
          </Link>
          .
        </Paragraph>

        <DontDo
          imgFilename="select-errorhelptext"
          dontText="allow errors without displaying help text."
          doText="use help text to explain why an error occurred and how it's fixed."
        />

        <DontDo
          imgFilename="select-single-error-pill"
          dontText="provide generic error text when it doesn't apply to all items."
          doText="write error text to help resolve a single error."
        />
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Use a{' '}
          <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/pickers/">
            picker
          </Link>{' '}
          to display the results when selecting a single option. If using our
          React Native component, this is done for you.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>List Length</SectionSubhead>
        <Paragraph>
          A select works for shorter lists with familiar options, ideally 5-15
          items. For fewer options, use a{' '}
          <Link href="/components/forms/radio" isDesignCodeLink>
            radio
          </Link>
          . For more than 15, enable search functionality.
        </Paragraph>
        <DontDo
          dontText="make the list too long with options the user may not predict."
          doText="keep the list short so typeahead is not needed."
          imgFilename="selects-listlength"
        />

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Option content should be familiar to the user–they should have an idea
          of what's in store before opening the select.
        </Paragraph>
        <DontDo
          dontText="frame every item differently in an unhelpful order."
          doText="provide a logical list of related items."
          imgFilename="selects-order"
        />

        <SectionSubhead>Option Groups</SectionSubhead>
        <Paragraph>
          Use option groups to divide larger lists into logical sections, like
          putting the most popular items at the top. You can also add labels for
          increased clarity.
        </Paragraph>
        <ComponentPreview name="selectGroup" layout="halfWidth">
          <div style={{ width: '50%' }}>
            <Select formSize="medium" options={groupOptions} />
          </div>
        </ComponentPreview>

        <SectionSubhead>Custom Options</SectionSubhead>
        <Paragraph>
          Options are not limited to a single line of text. Additional text and
          images are allowed, just be consistent with the content.
        </Paragraph>
        <Paragraph>
          A common custom option example is listing athletes with avatars–it
          should always be for the sake of helping users find what they're
          looking for.
        </Paragraph>
        <Paragraph>
          For complex lists, use option groups to divide items into logical
          sections, like putting the most popular items at the top. You can also
          add labels for increased clarity.
        </Paragraph>
        <ComponentPreview name="selectCustom" layout="halfWidth">
          <div style={{ width: '50%' }}>
            <Select
              formSize="medium"
              options={customOptions}
              components={{ Option }}
            />
          </div>
        </ComponentPreview>
        <Paragraph>
          If you do include additional text and/or images, the closed height
          cannot change once an option is selected.
        </Paragraph>
        <DontDo
          dontText="expand the closed state for large selected options."
          doText="modify the selected item preview to match the placeholder size."
          imgFilename="selects-placeholdersize"
        />

        <SectionSubhead>List Height</SectionSubhead>
        <Paragraph>
          Allow enough space in your interface or adjust the max-height to
          prevent the option list from expanding beyond the screen.
        </Paragraph>
        <Paragraph>
          By default, 6.5 items will show when the option list is expanded. If
          you use option groups or custom options, adjust the max-height to
          ensure it falls on a ½ item to signal the user can scroll.
        </Paragraph>

        <DontDo
          dontText="allow overflow to land between options and give the impression all options are visible."
          doText="size the maximum height to split an option and make scrolling obvious."
          imgFilename="selects-overflow"
        />
        <SectionSubhead>Max Height</SectionSubhead>
        <Paragraph>
          Should the selected items exceed one line, the default max is set to
          2.5rem. If you need something larger, we suggest the half-line to
          indicate more items are listed and the user can scroll. The sole
          exception is using the lookup multi-select type. The input should grow
          to accomodate all selected items in the pill list.
        </Paragraph>
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Forms / Select" />

      <Section title="Platform">
        <PlatformTable
          platforms={{
            apple: (
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://developer.apple.com/ios/human-interface-guidelines/controls/pickers/">
                    HIG
                  </Link>
                  .
                </li>
              </List>
            ),
            android: (
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://material.io/guidelines/components/lists-controls.html">
                    Material Guidelines
                  </Link>
                  .
                </li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
